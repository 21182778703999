<template>
  <ListHorizontal
    :title="props.title"
    :sub-title="props.subTitle"
    :list-link="selectedCategory.id === 0 ? {} : listLinkOptions"
    :slides="productList"
    :category-list="
      (showCategories && [...topCategories, ...categoriesList]) || []
    "
    :rows="props.rows"
    :select-category="selectCategory"
    :title-class="titleClass"
    :slides-per-view="props.slidesPerView"
    :breakpoints="props.breakpoints"
    :navigation-class="props.navigationClass"
    :virtual="props.virtual"
  >
    <template #default="{ slide }">
      <Item
        class="first:border-y border-l last:border-r mb-7 bg-white"
        :product="slide"
        :is-image-path="props.isImagePath"
        :is-watched-product="props.isWatchedProduct"
      />
    </template>
  </ListHorizontal>
</template>

<script setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import Item from './Item.vue'
import { useMenuStore } from '~/stores/menu'
import ProductVariantEntity from '~/entities/ProductVariant.entity'

const menuStore = useMenuStore()
const { categoriesList } = storeToRefs(menuStore)

const { t, locale } = useI18n()

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  productList: {
    type: Array,
    default: () => []
  },
  listLink: {
    type: Object,
    default: null
  },
  listType: {
    type: String,
    default: ''
  },
  titleClass: {
    type: String,
    default: undefined
  },
  slideWidth: {
    type: [Number, String],
    default: 190
  },
  rows: {
    type: [Number, String],
    default: 1
  },
  showCategories: {
    type: Boolean,
    default: false
  },
  selectCategoryCode: {
    type: Function,
    default: () => {}
  },
  slidesPerView: {
    type: [Number, String],
    default: 2
  },
  isImagePath: {
    type: Boolean,
    default: false
  },
  virtual: {
    type: Boolean,
    default: false
  },
  isWatchedProduct: {
    type: Boolean,
    default: false
  },
  breakpoints: {
    type: Object,
    default: () => ({
      600: {
        slidesPerView: 3
      },
      900: {
        slidesPerView: 4
      },
      1050: {
        slidesPerView: 5
      },
      1200: {
        slidesPerView: 6
      },
      1350: {
        slidesPerView: 7
      },
      1500: {
        slidesPerView: 8
      }
    })
  },
  navigationClass: {
    type: String,
    default: ''
  }
})

const selectCategory = category => {
  selectedCategory.value = category
  props.selectCategoryCode(category.code)
}

const topCategories = [
  {
    id: 0,
    code: 0,
    name: t('ТОП-категорії')
  }
]

const listLinkOptions = computed(() => {
  if (!props.listType) {
    return props.listLink
  }
  return {
    to: ProductVariantEntity.buildCatalogCategoryLink({
      categorySlug: selectedCategory.value?.slug,
      hostname: selectedCategory.value?.channelHostname,
      locale: locale.value,
      sort: props.listType
    }),
    label: props.listLink.label
  }
})

const selectedCategory = ref({ id: 0 })
</script>
