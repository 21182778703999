import ProductEntity from '~/entities/Product.entity'
import Collection from '~/entities/Collection'
export const useProductsSliderStore = defineStore({
  id: 'products-slider-store',
  state: () => {
    return {
      list: {
        new: [],
        hit: [],
        action: []
      },
      associatedList: {
        related: [],
        similar: []
      },
      relatedProducts: [],
      variantCode: null
    }
  },
  actions: {
    fetchProductsByTag(query, type) {
      return useApi().productVariants.getByTag(type, query, {
        auth: false,
        onSuccess: res => {
          this.list[type] = ProductEntity.parseApiResponse(res).data
        }
      })
    },
    fetchAssociatedProducts(productCode, associationType) {
      return useApi().productAssociations.getAll(
        {
          'type.code': associationType,
          'owner.code': productCode
        },
        {
          auth: false,
          onSuccess: res => {
            this.associatedList[associationType] = new Collection(
              res
            )?.data?.[0]?.associatedProducts?.map(v => new ProductEntity(v))
          }
        }
      )
    },
    setRelatedProducts({ variantCode, relatedProducts }) {
      this.relatedProducts = relatedProducts
      this.variantCode = variantCode
    }
  },
  getters: {
    productsList: state => state?.list,
    relatedProductsList: state => state?.relatedProducts,
    relatedVariantCode: state => state?.variantCode,
    productsAssociatedList: state => state?.associatedList
  }
})
